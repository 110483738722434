import * as React from 'react'
import BlogPostOld from "../../components/blog-post-old";
import image from "../../images/blog34/image1.png"
import image2 from "../../images/blog34/image2.png"
import image3 from "../../images/blog34/image3.jpg"
import image4 from "../../images/blog34/image4.png"
import image5 from "../../images/blog34/image5.jpg"








export default function HowToIncreaseClubHeadSpeed(){
    return(
        <BlogPostOld
            src={image}
            title={"How to Increase Club Head Speed"}
            summary={"Let’s be honest, we all dig the long ball. It’s no wonder why club head speed has become the talk of the clubhouse, especially the past decade with how accessible it has become to measure these things."}
            date={"Apr. 25, 2023"}
            author={"Dr. Donald Mull, DC"}

        >
            <div>
                <p>Let’s be honest, we all dig the long ball. It’s no wonder why club head speed has become the talk of the clubhouse, especially the past decade with how accessible it has become to measure these things.</p>
                <p>It is one thing to be able to measure club head speed. It is a whole other ball game to be able to change this for the positive.</p>
                <p>From a physical development perspective, this is a pretty straightforward concept. However, in order to understand how one should be trained to increase club speed, we first need to understand the demands of the sport of golf itself.</p>
                <p>I will introduce the main physical components of golf, but we will focus mainly on those that should be prioritized with the goal of club head speed since that is the name of this blog. Keep in mind that the other qualities listed should still be trained at least to maintain an acceptable base.</p>
                <p><strong>Cardio Demands of Golf</strong></p>
                <p>Though you aren’t running a marathon, golf does require enduring long periods of standing and walking. The average length of a golf course is about 5 miles <span style={{color: '#595959'}}>(</span><a href="https://www.npr.org/sections/health-shots/2015/06/15/413683367/take-a-swing-at-this-golf-is-exercise-cart-or-no-cart#:~:text=The%20World%20Golf%20Foundation%20estimates,burn%20up%20to%202%2C000%20calories." target="_blank" rel="noopener"><span style={{color: '#0097a7'}}>World Golf Foundation</span></a><span style={{color: '#595959'}}> estimate) </span>and even if you are in a cart, the round lasts 3-5 hours of on and off standing.</p>
                <p><strong>Mobility Needed in Golf</strong></p>
                <p>I am going to avoid getting too technical here and define mobility very simply: the ability to get into and out of a position. Getting the ball out of the hole, standing awkwardly in a sand trap, getting to the depths of your backswing and follow through are just some of the awkward positions one would need to get into during a round of golf.&nbsp;</p>
                <p><strong>Muscular Strength Necessary for Golf</strong></p>
                <p>Which muscles are the most important for the golf swing? You can start by looking at which muscles are used during the swing, which is damn near all of them. This is why I think a general approach to building muscle is best.&nbsp;</p>
                <p>I lean toward the teachings of Al Vermeil, former strength coach of Chicago Bears, Chicago Bulls and current consultant with Titleist Performance Institute, and prefer to build general strength along with work capacity (repeatability) prior to training for speed and power. He is the only coach to be a part of a championship winning team from 2 of the 3 major sports and he has proof of concept with some of the most explosive athletes in the world so I am willing to follow his lead here. Here is a diagram of his hierarchy of training.</p>
                <img src={image3} alt={"blog"} />
                <p><strong>Power and Speed Demands</strong></p>
                <p>This is the section in which I will spend more time assuming the foundation has been laid out in the others and no stone has been left unturned elsewhere.&nbsp;</p>
                <p>We need to remember that golf is a power sport and is all about producing a lot of force and very quickly. To give you an idea, the average force placed on a golf ball is about 2,000 to 4,000 lbs at impact (all occurring in less than half a millisecond).</p>
                    <img src={image4} alt={"blog"} />
                    <p>The average club head speed on tour in the 2021-2022 season was 115 mph according to <a href="https://www.graff.golf/the-club/here-is-the-average-pga-tour-swing-speed" target="_blank" rel="noopener">Graff Golf</a>. For the amateur golfer that number drops but is still an incredible average of 93.4 mph. This speed is generated very rapidly as the downswing takes on average .25 seconds on the PGA tour according to <a href="https://golf.com/instruction/swing-slow-practice-quickly-improve-your-swing/" target="_blank" rel="noopener">golf.com</a>.&nbsp;</p>
                        <img src={image2} alt={"blog"} />
                        <p>That stick is moving insanely fast, so your muscles need to be strong and you need to be able to express this strength in milliseconds or it does no good in a golf swing. Keep this in mind when we start to talk about ways to build physical qualities that will foster higher swing speeds.</p>
                <p>If we know we need an incredible amount of force into the ball and in as little time as possible, how should we plan to train to achieve this? I will re-emphasize what I said earlier - no stone should be left unturned and all qualities should be trained, to what degree depends on the context of your goals, your abilities and proximity to performance dates. However if I were to strictly stick with developing speed and power, here is how I would do it.</p>
                <p><strong>How to Increase Swing Speed</strong></p>
                <p>Given strength has been built to the point it can be trained multiple times a week without much fatigue, it is time to move things fast. When I help golfers who have injuries or just want to stay healthy, I get them back moving with more confidence in their swing then ever before and we hardly ever do exercises that look like the golf swing.</p>
                <p>To me this is load management. I don’t need to have you swing more using the same muscles, tendon and joints in the same direction and in the same ways over and over again. This helps avoid the repetitive, overuse type of injuries or nagging aches.</p>
                <p>Titliest Performance Institute, one of the leaders in the industry as far as accumulating biomechanical data from tour and ametuer players can predict what your club head speed is going to be within a few MPH just by measuring three things. Can you guess what they are? I will give you a hint: none look like a golf swing.&nbsp;</p>
                <p>Bet you didn’t guess. So I’ll tell you. One is a drop jump (step off a box and jump up as fast and as high as you can measuring how fast your lower body muscles can contract and how much force you can put into the ground), the second is a seated medicine ball chest pass for distance (basically how fast your upper body musculature can contract) and third a sit up medicine ball toss for distance (essentiall how fast your torso or mid section musculature can contract). See, yold you that you wouldn’t be able to guess.</p>
                <p>This has actually been proven in multiple peer reviewed articles from elite golfers to amatuers. (1, 2, 3) Those who perform well on a drop jump from a box are more likely to have higher club head speed. So from my point of view, this is the low hanging fruit. Developing the physical qualities to be more bouncy and be as strong as possible in as little time as possible is an absolute must for a golfer who wants to increase their swing speed.&nbsp;</p>
                <p>To achieve this, training must be gear towards giving you the option to express as much force as quickly as possible. Force comes down to a simple equation: Force = Mass x Acceleration.&nbsp;</p>
                <p>You need to be able to push your feet rapidly into the ground, whip your body around and throw the club all with incredibly fast muscle contractions. This requires a fast and loud signal from the brain to the muscles - which is trainable!</p>
                <p>If you remember you have about .25 seconds to generate anywhere between 2 and 4 thousand pounds of force on that golf ball. In the weightroom there are very few options we have here to train this and ideally create a buffer and expose you to even faster contractions with even more force.&nbsp;</p>
                <p>A traditional squat with weight you can lift only one time takes 3-4 seconds to lift, while repeat jumps take .150 - .250 milliseconds. I think there is a time and place for both of these and golfers do not take advantage of this concept enough. If we look at the below graph, the absolute fastest contraction we can train is sprinting.&nbsp;</p>
                            <img src={image5} alt={"blog"} />
                            <p>For this reason, I think we will see more PGA tour athletes implementing more jump and sprint-like training into their routine. This should be done slowly because when speeds are increased, so are the risks of soft tissue injuries like muscle tears. Though risks get higher when training these higher speeds, exposure to this acts like a vaccine that reduces risks and severity of soft tissue injuries when exposed to them while swinging that club 95-120 mph.&nbsp;</p>
                <p>At the end of the day, to swing the club faster you need to produce a lot of force into the ground during the swing, contract muscles extremely fast all while staying durable (the best ability is availability). Below are some key elements that should help develop these physical qualities without doing gimmicky exercises that may look like a swing but don’t produce enough force to even touch the demands of golf. I will give some examples of parameters for a rough guideline and liste some exercises by name. If this isn’t crystal clear for you, you need a coach. And that’s okay. Don’t be stubborn - I don’t try to do my own taxes and its for a reason.</p>
                <p><strong>Heavy weightlifting (80% RM +) for 3-5 sets of 3-5 reps</strong></p>
                <ul>
                    <li>Quarter Squat</li>
                    <li>Rear Foot Elevated Squat</li>
                    <li>Quarter Step Up</li>
                    <li>Hex Bar Deadlift</li>
                </ul>
                <p><strong>Velocity Based Training (.7 - .1.3 m/s) for 2-5 sets of 3-6 reps&nbsp; (Speed - Strength)</strong></p>
                <ul>
                    <li>Hex Bar Deadlift</li>
                    <li>Back Squat</li>
                    <li>Front Squat</li>
                    <li>Bench Press</li>
                    <li>Bent Over Row</li>
                </ul>
                <p><strong>Plyometric and Jump Training (progressed to ground contact time ideally .25 or less) Below is a list that is roughly from easiest to hardest to help with scalability)</strong></p>
                <ul>
                    <li>Fast calf Raises</li>
                    <li>Hand Assisted Pogo Leap</li>
                    <li>Captain Morgan Fast calf Raises</li>
                    <li>Captain Morgan Pogo Hops</li>
                    <li>Pogo Leaps</li>
                    <li>Pogo Hops</li>
                    <li>Single Vertical Jumps</li>
                    <li>Vertical Leap Repeats</li>
                    <li>Vertical Leap Knee Tucks</li>
                    <li>Verical Hops</li>
                    <li>Zig Zag Hops</li>
                </ul>
                <p><strong>Medicine Ball Throws with 4 lb to 8 lb balls (about speed not heavy weight)</strong></p>
                <ul>
                    <li>Slams</li>
                    <li>Fake Slams</li>
                    <li>Keg Toss</li>
                    <li>Fake Keg Toss</li>
                    <li>Kneeling Acceleration Toss</li>
                    <li>Scoop Toss</li>
                    <li>Step Scoop Toss</li>
                </ul>
                <p><strong>Acceleration Drills (starting with 1 step up to 20 yds)</strong></p>
                <ul>
                    <li>Banded 1st Step</li>
                    <li>Sled Push</li>
                    <li>Medicine Ball Toss Starts</li>
                    <li>Kneeling Starts</li>
                    <li>Lateral Kneeling Starts</li>
                </ul>
                <p><strong>Change of Direction and Reactionary Cutting Drills</strong></p>
                <ul>
                    <li>Speed Shuffle</li>
                    <li>Step Over</li>
                    <li>Eurostep Bounds</li>
                </ul>
                <p><strong>Max Speed Training (this should be slowly progressed into over 8-12 weeks minimum for most individuals and longer for those who have not been exposed to sprinting on a weekly basis regularly for 3-6 months)</strong></p>
                <ul>
                    <li>Assault treadmill build up to max speed&nbsp;</li>
                    <li>Flying 20 yd sprints</li>
                </ul>
                <p>In conclusion, training to increase your swing speed comes down to three major categories: having a solid foundation of general strength and fitness, producing high amounts of force into the ground quickly and having the ability to contract and recruit muscular activity incredibly fast. In my opinion, this should not look like the golf swing because it mitigates risk for overuse injuries AND exercises that look like a swing do not produce as much speed or force than the above mentioned concepts and exercises.</p>
                <p>Resources</p>
                <ol>
                    <li><a href="https://pubmed.ncbi.nlm.nih.gov/22797001/" target="_blank" rel="noopener">https://pubmed.ncbi.nlm.nih.gov/22797001/</a></li>
                    <li><a href="https://pubmed.ncbi.nlm.nih.gov/29300147/" target="_blank" rel="noopener">https://pubmed.ncbi.nlm.nih.gov/29300147/</a></li>
                    <li><a href="https://pubmed.ncbi.nlm.nih.gov/35225155/" target="_blank" rel="noopener">https://pubmed.ncbi.nlm.nih.gov/35225155/</a></li>
                </ol>
            </div>

        </BlogPostOld>

    )
}